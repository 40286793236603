import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'gatsby';
import HeaderExpanded from '../components/HeaderExpanded/HeaderExpanded';
import '../styles/404.scss';
import { Helmet } from 'react-helmet';

function _404() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const title = 'Page Not Found';
  const image =
    process.env.GATSBY_S3_BUCKET_ASSETS + 'images/apple_tree_closeup.jpg';

  return (
    <>
      {/* Add meta tags inside here. */}
      <Helmet>
        <title>HortPlus | Page not found</title>
        <meta
          name='description'
          content='Page not found'
        />
      </Helmet>
      <HeaderExpanded title={title} image={image} />
      <Container className='hp-padding-100 mb-5'>
        <p className='hp-font-22'>
          <b>Page Not Found</b>
        </p>
        <p>
          <Link to={'/'}>Return to home</Link>
        </p>
      </Container>
    </>
  );
}

export default _404;
